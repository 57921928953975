import { useCallback, useEffect, useRef } from 'react'

const useMountedState = () => {
  const mountedRef = useRef<boolean>(false)
  const get = useCallback(() => mountedRef.current, [])

  useEffect(() => {
    // useEffectはマウントが完了したタイミングでコールバックが実行されるので、
    // そのタイミングでマウント状態を設定する
    mountedRef.current = true

    // useEffectのコールバック関数でクリーンアップ関数を返すようにすると、
    // アンマウント時にクリーンアップ関数を実行する
    // そのタイミングでマウント状態を設定する
    return () => {
      mountedRef.current = false
    }
  }, [])

  return get
}

export default useMountedState
