import type { CSSProperties, VFC } from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import {
  Breadcrumbs as MuiBreadCrumbs,
  Typography,
  useTheme,
} from '@material-ui/core'

// パスがURLパラメータが含まれるなど動的である場合は、パンくずリストの終端に表示させたい文字列を指定する
type Props = {
  pathName?: string
}

const mainPathNameMap: Record<string, string> = {
  '/point': 'ポイント残高',
  '/customers': '顧客',
}

const BreadCrumbs: VFC<Props> = ({pathName}) => {
  const location = useLocation()
  const theme = useTheme()

  const linkStyle: CSSProperties = {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  }

  const pathNames = location.pathname.split('/').filter((x) => x)

  return (
    <MuiBreadCrumbs separator=">" aria-label="breadcrumb">
      <RouterLink to="/" style={linkStyle}>
        Home
      </RouterLink>
      {pathNames.map((path, index) => {
        const isLast = pathNames.length - 1 === index
        const to = `/${pathNames.slice(0, index + 1).join('/')}`

        return isLast ? (
          // 終端の場合はリンクではなくテキストとする
          // パスにURLパラメータが含まれているなど動的なパスの場合はPropsで渡された値を表示する
          <Typography key={to} color="textSecondary">
            {mainPathNameMap[to] ?? pathName ?? path}
          </Typography>
        ) : (
          <RouterLink to={to} key={to} style={linkStyle}>
            <Typography>{mainPathNameMap[to] ?? path}</Typography>
          </RouterLink>
        )
      })}
    </MuiBreadCrumbs>
  )
}

export default BreadCrumbs
