import type { VFC } from 'react'
import { Helmet } from 'react-helmet-async'

type Props = {
  title: string
}

const CustomHelmet: VFC<Props> = ({ title }) => (
  <>
    <Helmet>
      <title>{title} | mixpace monitor</title>
      <meta name="description" content="mixpace monitor" />
    </Helmet>
  </>
)

export default CustomHelmet
