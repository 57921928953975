import { VFC } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { setContext } from '@apollo/client/link/context'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'

const scopes = ['api://85264981-f858-408f-a763-1b1e4991bc97/access']

const GraphQLClient =
  (Component: VFC): VFC =>
  () => {
    // HOCみたいにコンポーネントを引数にして関数コンポーネントを返す関数なので、
    // フックのルールに反していないのでここではルールを抑制する
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { instance, accounts, inProgress } = useMsal()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const account = useAccount(accounts[0] || {})

    const acquireToken = async () => {
      if (inProgress !== 'none') {
        return null
      }

      try {
        const result = await instance.acquireTokenSilent({
          scopes,
          account: account ?? undefined,
        })

        return result.accessToken
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect({ scopes })
        }

        return null
      }
    }

    const withTokenLink = setContext(
      async (_, { headers }: { headers: Record<string, unknown> }) => {
        const token = await acquireToken()

        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null,
          },
        }
      },
    )

    const httpLink = createHttpLink({
      uri: 'https://mixpace-monitor-api-apim.azure-api.net/mixpace-monitor-api/MixpaceGraphQL',
    })

    const apolloClient = new ApolloClient({
      link: from([withTokenLink, httpLink]),
      connectToDevTools: true,
      cache: new InMemoryCache(),
    })

    return (
      <ApolloProvider client={apolloClient}>
        <Component />
      </ApolloProvider>
    )
  }

export default GraphQLClient
