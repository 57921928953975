import { VFC } from 'react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

const authConfig: Configuration = {
  auth: {
    // ブラウザ側に渡される情報なのでマスクする必要なし
    clientId: 'a4d899eb-00c7-491d-b978-33c0c691a41e',
    authority:
      'https://login.microsoftonline.com/82c5b199-4987-4fce-a92b-97e781eadf7c',
    redirectUri:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://dev.monitor.mixpace.jp',
    postLogoutRedirectUri:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://dev.monitor.mixpace.jp',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

const msalInstance = new PublicClientApplication(authConfig)

const Authentication =
  (Component: VFC): VFC =>
  () =>
    (
      <MsalProvider instance={msalInstance}>
        <Component />
      </MsalProvider>
    )

export default Authentication
