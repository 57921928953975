import { useRef } from 'react'
import useEffectOnce from './useEffectOnce'

/**
 * アンマウント時に指定した関数を評価するhook
 * @param fn
 */
const useUnmount = (fn: () => void): void => {
  const fnRef = useRef<() => void>(fn)

  fnRef.current = fn

  // hookの入力で渡された関数を返す関数をuseEffectOnceに渡す
  // これは副作用としては何もなく、クリーンアップだけuseEffectに渡すのとほぼ同義
  useEffectOnce(() => () => fnRef.current())
}

export default useUnmount
