import { useCallback, useState } from 'react'

/**
 * setterでStateの更新を行うとき、古いStateと新しいStateをMergeしていく
 * @param initialState
 */
const useSetState = <T extends Record<string, unknown>>(
  initialState: T = {} as T,
): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
  const [state, set] = useState<T>(initialState)
  const setState = useCallback(
    (patch: Partial<T> | ((prevState: T) => Partial<T>)) => {
      set((prevState) => ({
        ...prevState,
        ...(patch instanceof Function ? patch(prevState) : patch),
      }))
    },
    [],
  )

  return [state, setState]
}

export default useSetState
