import type { VFC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core'
import {
  ArrowForward as ArrowForwardIcon,
  MailOutline as MailIcon,
} from '@material-ui/icons'
import { format } from 'date-fns/fp'

type PointBalanceSummary = {
  customerId: string
  customerName: string
  totalCharge: number
  balance: number
  consumption: number
  contractStartDate: Date
  contractEndDate: Date
  type: 'list' | 'detail'
}

const balanceThreshold = 20000

const termStr = (startDate: Date) => (endDate: Date) => {
  const formatFunc = format('yyyy年 M月d日')

  return `${formatFunc(startDate)} 〜 ${formatFunc(endDate)}`
}

const PointBalanceSummary: VFC<PointBalanceSummary> = ({
  customerId,
  customerName,
  balance,
  totalCharge,
  consumption,
  contractStartDate,
  contractEndDate,
  type,
}) => (
  <Card>
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* 顧客名ブロック */}
      <Typography
        color="textPrimary"
        variant="subtitle2"
        sx={{ textAlign: 'left' }}
      >
        {customerName}
      </Typography>
      {/* ポイント情報ブロック */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography
          color={balance > balanceThreshold ? 'primary' : 'secondary.light'}
          variant="h4"
        >
          {balance.toLocaleString()} pt
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              color="textSecondary"
              sx={{ pr: 2 }}
              variant="subtitle2"
            >
              総額
            </Typography>
            <Typography variant="subtitle2">
              {totalCharge.toLocaleString()} pt
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              color="textSecondary"
              sx={{ pr: 2 }}
              variant="subtitle2"
            >
              消費
            </Typography>
            <Typography variant="subtitle2">
              {consumption.toLocaleString()} pt
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* 契約期間ブロック */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          mt: 1,
        }}
      >
        <Typography color="textSecondary" variant="subtitle2" sx={{ pr: 2 }}>
          契約期間
        </Typography>
        <Typography variant="subtitle2">
          {termStr(contractStartDate)(contractEndDate)}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {type === 'list' ? (
        <RouterLink
          to={`/point/${customerId}`}
          style={{
            textDecoration: 'none',
          }}
        >
          <Button
            color="primary"
            variant="text"
            aria-label={`detail-${customerId}`}
            endIcon={<ArrowForwardIcon />}
          >
            詳細
          </Button>
        </RouterLink>
      ) : (
        <Button
          color="primary"
          variant="text"
          aria-label={`mail-${customerId}`}
          startIcon={<MailIcon />}
        >
          警告メールを送る
        </Button>
      )}
    </CardActions>
  </Card>
)

export default PointBalanceSummary
