import { useState } from 'react'
import type { VFC, ReactNode } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Button, Collapse, ListItem } from '@material-ui/core'
import type { ListItemProps } from '@material-ui/core'
import Icon from '../Icon'

type NavItemProps = ListItemProps & {
  active?: boolean
  children?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open?: boolean
  path?: string
  title: string
}

const NavItem: VFC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props

  const [open, setOpen] = useState(openProp)

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // ブランチノード
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
        }}
        {...other}
      >
        <Button
          startIcon={icon}
          endIcon={
            open ? (
              <Icon.ChevronDownIcon fontSize="small" />
            ) : (
              <Icon.ChevronRightIcon fontSize="small" />
            )
          }
          onClick={handleToggle}
          variant="text"
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  // リーフノード
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
    >
      <RouterLink
        to={path ?? '/'}
        style={{
          textDecoration: 'none',
          width: '100%',
        }}
      >
        <Button
          startIcon={icon}
          variant="text"
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            textAlign: 'left',
            pl: `${paddingLeft}px`,
            pr: `8px`,
            py: `12px`,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main',
              fontWeight: 'fontWeightBold',
              '& svg': {
                color: 'primary.main',
              },
            }),
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </RouterLink>
    </ListItem>
  )
}

export default NavItem
