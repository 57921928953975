import type { VFC } from 'react'
import { Box, Typography } from '@material-ui/core'
import PageTransition from '../components/PageTransition'
import BreadCrumbs from '../components/BreadCrumbs'
import CustomHelmet from '../components/CustomHelmet'

const Dashboard: VFC = () => (
  <>
    <CustomHelmet title="ダッシュボード" />
    <Box
      sx={{
        m: 3,
      }}
    >
      <BreadCrumbs />
    </Box>
    <PageTransition>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'inherit',
        }}
      >
        <Typography variant="h3">ダッシュボードは未実装です</Typography>
      </Box>
    </PageTransition>
  </>
)

export default Dashboard
