import type { VFC } from 'react'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core'
import { useAccount, useMsal } from '@azure/msal-react'
import { useRef, useState } from 'react'

// TODO テーマの切り替え機能の検討
const AccountPopover: VFC = () => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  // ポップアップの開閉状態を表すステート
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleSignOut = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
  }

  const anchorRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderRadius: 1,
          py: 1,
        }}
      >
        <Typography color="primary.contrastText" variant="subtitle2" sx={{ mx: 1 }}>
          {account?.name ?? account?.username ?? ''}
        </Typography>
        <Avatar sx={{ width: 32, height: 32, ml: 1 }} />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted // 常にDOM内に子を保持する。このプロップは、SEOの状況やモーダルの応答性を最大化したい場合に役立ちます。
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 1 }}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            select
            SelectProps={{ native: true }}
            value="dark"
            variant="outlined"
          >
            {['dark', 'light'].map((theme) => (
              <option key={theme} value={theme}>
                {theme
                  .split('_')
                  .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                  .join(' ')}
              </option>
            ))}
          </TextField>
        </Box>
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            color="primary"
            onClick={handleSignOut}
            variant="outlined"
          >
            サインアウト
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default AccountPopover
