import type { VFC } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons'
import { Navigate } from 'react-router-dom'

import PointBalanceSummary from '../domain/PointBalanceSummary'
import Loading from '../components/Loading'
import { useAllCustomerBalancesQuery } from '../graphql/gen/action'
import PageTransition from '../components/PageTransition'
import BreadCrumbs from '../components/BreadCrumbs'
import CustomHelmet from '../components/CustomHelmet'

const PointBalanceList: VFC = () => {
  const { loading, data, error } = useAllCustomerBalancesQuery()

  const helmet = (
    <>
      <CustomHelmet title="ポイント残高" />
      <Box
        sx={{
          m: 3,
        }}
      >
        <BreadCrumbs />
      </Box>
    </>
  )

  if (loading) {
    return (
      <>
        {helmet}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          <Loading />
        </Box>
      </>
    )
  }

  if (data === undefined || data.customers === undefined || error) {
    return (
      <>
        <Navigate to="/error" />
      </>
    )
  }

  return (
    <>
      {helmet}
      <PageTransition>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            m: 3,
          }}
        >
          <Typography variant="h3">ポイント残高</Typography>
          <Button variant="contained" startIcon={<AddIcon />}>
            顧客追加
          </Button>
        </Box>
        <Container maxWidth={false}>
          <TextField
            size="small"
            placeholder="会社名"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
            }}
          />
          <Grid container columns={6} spacing={2}>
            {data.customers?.map((item) => (
              <Grid item key={item.id} xl={2} lg={3} md={3} sm={6} xs={6}>
                <PointBalanceSummary
                  customerId={item.id}
                  customerName={item.customerName}
                  totalCharge={
                    item.currentContract.remoteRenderingStates!.totalCharge
                  }
                  balance={item.currentContract.remoteRenderingStates!.balance}
                  consumption={
                    item.currentContract.remoteRenderingStates!
                      .totalConsumptionAmount
                  }
                  contractStartDate={new Date(item.currentContract.startDate)}
                  contractEndDate={new Date(item.currentContract.endDate)}
                  type="list"
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </PageTransition>
    </>
  )
}

export default PointBalanceList
