import { VFC, useState } from 'react'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'

import { Typography, Button, Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { Login as LoginIcon } from '@material-ui/icons'

import './App.css'
import { Routes, Route } from 'react-router'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import Authentication from './context/Authentication'
import GraphQLClient from './context/GraphQL'
import NavigationBar from './components/NavigationBar'
import CustomTheme from './context/Theme'
import SideBar from './components/SideBar'
import PointBalanceList from './pages/PointBalanceList'
import Dashboard from './pages/Dashboard'
import Customers from './pages/Customers'
import NotFoundError from './components/NotFoundError'
import ServerError from './components/ServerError'
import PointBalanceDetail from './pages/PointBalanceDetail'

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}))

const LayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px',
  },
}))

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
})

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

const App: VFC = () => {
  const { instance } = useMsal()
  const location = useLocation()

  const handleSignIn = async () => {
    await instance.loginPopup({
      scopes: ['openid', 'profile'],
    })
  }

  const [isMobileSideBarOpen, setIsMobileSideBarOpen] = useState<boolean>(false)

  return (
    <>
      <AuthenticatedTemplate>
        <LayoutRoot>
          <NavigationBar
            onSideBarMobileOpen={(): void => setIsMobileSideBarOpen(true)}
          />
          <SideBar
            openMobile={isMobileSideBarOpen}
            onMobileClose={(): void => setIsMobileSideBarOpen(false)}
          />
          <LayoutWrapper>
            <LayoutContainer>
              <LayoutContent>
                <AnimatePresence exitBeforeEnter initial>
                  <Routes key={location.pathname}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/point" element={<PointBalanceList />} />
                    <Route
                      path="/point/:customerId"
                      element={<PointBalanceDetail />}
                    />
                    <Route path="/error" element={<ServerError />} />
                    <Route path="*" element={<NotFoundError />} />
                  </Routes>
                </AnimatePresence>
              </LayoutContent>
            </LayoutContainer>
          </LayoutWrapper>
        </LayoutRoot>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Helmet>
          <title>mixpace monitor</title>
        </Helmet>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              my: 3,
            }}
          >
            mixpace monitor
          </Typography>
          <Button
            onClick={handleSignIn}
            variant="contained"
            startIcon={<LoginIcon />}
            sx={{
              my: 3,
            }}
          >
            サインイン
          </Button>
        </Box>
      </UnauthenticatedTemplate>
    </>
  )
}

const ThemedApp = CustomTheme(App)
const GQLApp = GraphQLClient(ThemedApp)
const AuthedApp = Authentication(GQLApp)

export default AuthedApp
