import type { VFC } from 'react'
import { useEffect } from 'react'
import type { Theme } from '@material-ui/core'
import { Box, Drawer, useMediaQuery } from '@material-ui/core'
import {
  MonetizationOn as MonetizationIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons'

import { useLocation } from 'react-router-dom'
import NavSection from './NavSection'
import UsersIcon from '../Icon/UsersIcon'

const sections = [
  {
    title: 'Menu',
    items: [
      {
        title: 'ダッシュボード',
        path: '/',
        icon: <DashboardIcon fontSize="small" />,
      },
      {
        title: '顧客',
        path: '/customers',
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'ポイント残高',
        path: '/point',
        icon: <MonetizationIcon fontSize="small" />,
      },
    ],
  },
]

type SideBarProps = {
  onMobileClose: () => void
  openMobile: boolean
}

const SideBar: VFC<SideBarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ p: 2 }}>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            items={section.items}
            pathname={location.pathname}
            title={section.title}
          />
        ))}
      </Box>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

export default SideBar
