import type { VFC } from 'react'
import { useCallback, useRef, useState } from 'react'
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core'
import { FileCopyOutlined as CopyIcon } from '@material-ui/icons'
import { MonthlyConsumption } from '../../graphql/gen/action'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import useEffectDelay from '../../hooks/useEffectDelay'

type Props = {
  consumption: MonthlyConsumption
}

const StatementRow: VFC<Props> = ({
  consumption: {
    term,
    totalAmount,
    standardSessionElapsedTime,
    standardSessionAmount,
    premiumSessionElapsedTime,
    premiumSessionAmount,
    remoteRenderingAssetConversionCount,
    remoteRenderingAssetConversionAmount,
  },
}) => {
  const [_, copyToClipboard] = useCopyToClipboard()

  const [isTooltipOpen, setTooltipOpenState] = useState(false)
  const [throttleFlag, setThrottleFlag] = useState(false)

  const handleCopyButtonClicked = useCallback(() => {
    copyToClipboard(totalAmount.toString())
    setTooltipOpenState(true)
    setThrottleFlag((prev) => !prev)
  }, [])

  useEffectDelay(
    () => {
      setTooltipOpenState(false)
    },
    1000,
    [throttleFlag],
  )

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle2">{term}</Typography>
      </TableCell>

      <TableCell align="right">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2">
            {totalAmount.toLocaleString()} pt
          </Typography>

          <Tooltip
            title="Copied!"
            placement="top"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Zoom}
            open={isTooltipOpen}
            arrow
          >
            <IconButton
              onClick={handleCopyButtonClicked}
              sx={{
                ml: 1,
              }}
              aria-label={`copy-${term}-subtotal`}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2" align="right">
          {standardSessionElapsedTime.toLocaleString()} 分 /{' '}
          {standardSessionAmount.toLocaleString()} pt
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2" align="right">
          {premiumSessionElapsedTime.toLocaleString()} 分 /{' '}
          {premiumSessionAmount.toLocaleString()} pt
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2" align="right">
          {remoteRenderingAssetConversionCount.toLocaleString()} 回 /{' '}
          {remoteRenderingAssetConversionAmount.toLocaleString()} pt
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default StatementRow
