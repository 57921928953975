import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: string
}

export type Contract = {
  __typename?: 'Contract'
  endDate: Scalars['Date']
  id: Scalars['ID']
  planName: Scalars['String']
  pointTickets?: Maybe<Array<PointTicket>>
  remoteRenderingStates?: Maybe<RemoteRenderingStates>
  startDate: Scalars['Date']
}

export type Customer = {
  __typename?: 'Customer'
  currentContract: Contract
  customerName: Scalars['String']
  id: Scalars['ID']
}

export type MonthlyConsumption = {
  __typename?: 'MonthlyConsumption'
  premiumSessionAmount: Scalars['Int']
  premiumSessionElapsedTime: Scalars['Int']
  remoteRenderingAssetConversionAmount: Scalars['Int']
  remoteRenderingAssetConversionCount: Scalars['Int']
  standardSessionAmount: Scalars['Int']
  standardSessionElapsedTime: Scalars['Int']
  term: Scalars['String']
  totalAmount: Scalars['Int']
}

export type PointTicket = {
  __typename?: 'PointTicket'
  charge: Scalars['Int']
  deliveryDate: Scalars['Date']
  depositedDate?: Maybe<Scalars['Date']>
  id: Scalars['ID']
}

export type Query = {
  __typename?: 'Query'
  _?: Maybe<Scalars['Boolean']>
  customer: Customer
  customers?: Maybe<Array<Customer>>
}

export type QueryCustomerArgs = {
  id: Scalars['ID']
}

export type RemoteRenderingStates = {
  __typename?: 'RemoteRenderingStates'
  balance: Scalars['Int']
  consumptionPerMonth?: Maybe<Array<MonthlyConsumption>>
  totalCharge: Scalars['Int']
  totalConsumptionAmount: Scalars['Int']
}

export type AllCustomerBalancesQueryVariables = Exact<{ [key: string]: never }>

export type AllCustomerBalancesQuery = {
  __typename?: 'Query'
  customers?: Maybe<
    Array<{
      __typename?: 'Customer'
      id: string
      customerName: string
      currentContract: {
        __typename?: 'Contract'
        planName: string
        startDate: string
        endDate: string
        remoteRenderingStates?: Maybe<{
          __typename?: 'RemoteRenderingStates'
          balance: number
          totalCharge: number
          totalConsumptionAmount: number
        }>
      }
    }>
  >
}

export type CustomerConsumptionQueryVariables = Exact<{
  customerId: Scalars['ID']
}>

export type CustomerConsumptionQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'Customer'
    id: string
    customerName: string
    currentContract: {
      __typename?: 'Contract'
      planName: string
      startDate: string
      endDate: string
      pointTickets?: Maybe<
        Array<{
          __typename?: 'PointTicket'
          id: string
          charge: number
          deliveryDate: string
          depositedDate?: Maybe<string>
        }>
      >
      remoteRenderingStates?: Maybe<{
        __typename?: 'RemoteRenderingStates'
        balance: number
        totalCharge: number
        totalConsumptionAmount: number
        consumptionPerMonth?: Maybe<
          Array<{
            __typename?: 'MonthlyConsumption'
            term: string
            standardSessionElapsedTime: number
            standardSessionAmount: number
            premiumSessionElapsedTime: number
            premiumSessionAmount: number
            remoteRenderingAssetConversionCount: number
            remoteRenderingAssetConversionAmount: number
            totalAmount: number
          }>
        >
      }>
    }
  }
}

export type PingQueryVariables = Exact<{ [key: string]: never }>

export type PingQuery = { __typename?: 'Query'; _?: Maybe<boolean> }

export const AllCustomerBalancesDocument = gql`
  query allCustomerBalances {
    customers {
      id
      customerName
      currentContract {
        planName
        startDate
        endDate
        remoteRenderingStates {
          balance
          totalCharge
          totalConsumptionAmount
        }
      }
    }
  }
`

/**
 * __useAllCustomerBalancesQuery__
 *
 * To run a query within a React component, call `useAllCustomerBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomerBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomerBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCustomerBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCustomerBalancesQuery,
    AllCustomerBalancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    AllCustomerBalancesQuery,
    AllCustomerBalancesQueryVariables
  >(AllCustomerBalancesDocument, options)
}
export function useAllCustomerBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCustomerBalancesQuery,
    AllCustomerBalancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    AllCustomerBalancesQuery,
    AllCustomerBalancesQueryVariables
  >(AllCustomerBalancesDocument, options)
}
export type AllCustomerBalancesQueryHookResult = ReturnType<
  typeof useAllCustomerBalancesQuery
>
export type AllCustomerBalancesLazyQueryHookResult = ReturnType<
  typeof useAllCustomerBalancesLazyQuery
>
export type AllCustomerBalancesQueryResult = Apollo.QueryResult<
  AllCustomerBalancesQuery,
  AllCustomerBalancesQueryVariables
>
export const CustomerConsumptionDocument = gql`
  query customerConsumption($customerId: ID!) {
    customer(id: $customerId) {
      id
      customerName
      currentContract {
        planName
        startDate
        endDate
        pointTickets {
          id
          charge
          deliveryDate
          depositedDate
        }
        remoteRenderingStates {
          balance
          totalCharge
          totalConsumptionAmount
          consumptionPerMonth {
            term
            standardSessionElapsedTime
            standardSessionAmount
            premiumSessionElapsedTime
            premiumSessionAmount
            remoteRenderingAssetConversionCount
            remoteRenderingAssetConversionAmount
            totalAmount
          }
        }
      }
    }
  }
`

/**
 * __useCustomerConsumptionQuery__
 *
 * To run a query within a React component, call `useCustomerConsumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerConsumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerConsumptionQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerConsumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomerConsumptionQuery,
    CustomerConsumptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<
    CustomerConsumptionQuery,
    CustomerConsumptionQueryVariables
  >(CustomerConsumptionDocument, options)
}
export function useCustomerConsumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerConsumptionQuery,
    CustomerConsumptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<
    CustomerConsumptionQuery,
    CustomerConsumptionQueryVariables
  >(CustomerConsumptionDocument, options)
}
export type CustomerConsumptionQueryHookResult = ReturnType<
  typeof useCustomerConsumptionQuery
>
export type CustomerConsumptionLazyQueryHookResult = ReturnType<
  typeof useCustomerConsumptionLazyQuery
>
export type CustomerConsumptionQueryResult = Apollo.QueryResult<
  CustomerConsumptionQuery,
  CustomerConsumptionQueryVariables
>
export const PingDocument = gql`
  query ping {
    _
  }
`

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(
  baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options)
}
export function usePingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }

  return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(
    PingDocument,
    options,
  )
}
export type PingQueryHookResult = ReturnType<typeof usePingQuery>
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>
