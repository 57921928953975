import { useEffect, useRef } from 'react'
import useUnmount from './useUnmount'

const useEffectDelay = (fn: () => void, ms = 1000, args: [boolean]): void => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    timeout.current = setTimeout(fn, ms)
  }, args)

  useUnmount(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  })
}

export default useEffectDelay
