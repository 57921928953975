import type { SyntheticEvent, VFC } from 'react'
import { useState } from 'react'
import Chart from 'react-apexcharts'
import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ApexOptions } from 'apexcharts'
import * as Array from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'

type MonthlyConsumption = {
  term: string
  totalAmount: number
  standardSessionElapsedTime: number
  standardSessionAmount: number
  premiumSessionElapsedTime: number
  premiumSessionAmount: number
  remoteRenderingAssetConversionCount: number
  remoteRenderingAssetConversionAmount: number
}

type Props = {
  consumptions: MonthlyConsumption[]
}

type Target = 'session' | 'conversion'

const chartOption =
  (theme: Theme) =>
  (target: Target) =>
  (consumptions: MonthlyConsumption[]): ApexOptions => {
    const sessionFormatter = (val: number | string) => `${val} 分`
    const conversionFormatter = (val: number | string) => `${val} 回`

    return {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 300,
          },
        },
        background: 'transparent',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter:
          target === 'session' ? sessionFormatter : conversionFormatter,
        style: {
          fontWeight: 500,
          colors: [theme.palette.text.primary],
        },
        dropShadow: {
          enabled: false,
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        show: true,
        borderColor: theme.palette.divider,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: true,
        horizontalAlign: 'center',
        itemMargin: {
          horizontal: 16,
          vertical: 8,
        },
      },
      theme: {
        mode: theme.palette.mode,
        palette: theme.palette.mode === 'light' ? 'palette4' : 'palette2',
      },
      stroke: {
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        categories: pipe(
          consumptions,
          Array.map((consumption) => consumption.term),
          Array.reverse,
        ),
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: {
        labels: {
          offsetX: -8,
          style: {
            colors: theme.palette.text.secondary,
          },
          formatter:
            target === 'session' ? sessionFormatter : conversionFormatter,
        },
      },
    }
  }

const generateSeries =
  (target: Target) => (consumptions: MonthlyConsumption[]) =>
    target === 'session'
      ? [
          {
            name: 'Standard',
            data: pipe(
              consumptions,
              Array.map(
                (consumption) => consumption.standardSessionElapsedTime,
              ),
              Array.reverse,
            ),
          },
          {
            name: 'Premium',
            data: pipe(
              consumptions,
              Array.map((consumption) => consumption.premiumSessionElapsedTime),
              Array.reverse,
            ),
          },
        ]
      : [
          {
            name: 'Conversion',
            data: pipe(
              consumptions,
              Array.map(
                (consumption) =>
                  consumption.remoteRenderingAssetConversionCount,
              ),
              Array.reverse,
            ),
          },
        ]

const RemoteRenderingSessionUsageChart: VFC<Props> = ({ consumptions }) => {
  const theme = useTheme()
  const [currentTarget, setCurrentTarget] = useState<Target>('session')
  const handleTabsChange = (event: SyntheticEvent, value: Target): void => {
    setCurrentTarget(value)
  }

  return (
    <>
      <Card>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h6">リモートレンダリング利用状況</Typography>
        </Box>
        <Divider />
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTarget}
          centered
        >
          <Tab key="session" label="セッション" value="session" />
          <Tab key="conversion" label="コンバート" value="conversion" />
        </Tabs>
        <Box
          sx={{
            px: 2,
          }}
        >
          <Chart
            key={currentTarget}
            type="bar"
            height="360"
            series={generateSeries(currentTarget)(consumptions)}
            options={chartOption(theme)(currentTarget)(consumptions)}
          />
        </Box>
      </Card>
    </>
  )
}

export default RemoteRenderingSessionUsageChart
