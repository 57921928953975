import { VFC } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'

const Loading: VFC = () => (
  <>
    <CircularProgress />
    <Typography>Loading...</Typography>
  </>
)

export default Loading
