import type { ReactNode, VFC } from 'react'
import { motion } from 'framer-motion'

type PageTransitionProps = {
  children?: ReactNode
}

const PageTransition: VFC<PageTransitionProps> = ({ children }) => (
  <motion.div
    animate={{
      y: 0,
      opacity: 1,
    }}
    initial={{
      y: 25,
      opacity: 0,
    }}
    exit={{
      y: 25,
      opacity: 0,
    }}
    transition={{
      duration: 0.4,
    }}
    style={{
      height: 'inherit',
    }}
  >
    {children}
  </motion.div>
)

export default PageTransition
