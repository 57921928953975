import { VFC } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Create as PencilIcon, Add as AddIcon } from '@material-ui/icons'
import { format } from 'date-fns/fp'
import * as Array from 'fp-ts/Array'

type PointTicketData = {
  id: string
  charge: number
  deliveryDate: Date
  depositedDate: Date | null
}

type Props = {
  tickets: PointTicketData[]
}

const generateRow = ({
  id,
  charge,
  deliveryDate,
  depositedDate,
}: PointTicketData) => (
  <TableRow key={id} hover>
    <TableCell>
      <Typography variant="subtitle2">{charge.toLocaleString()} pt</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="subtitle2">
        {format('yyyy年 M月d日')(deliveryDate)}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography variant="subtitle2">
        {depositedDate ? format('yyyy年 M月d日')(depositedDate) : 'N/A'}
      </Typography>
    </TableCell>
    <TableCell align="center">
      <IconButton>
        <PencilIcon fontSize="small" />
      </IconButton>
    </TableCell>
  </TableRow>
)

const TicketPurchaseHistory: VFC<Props> = ({ tickets }) => {
  const isMobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  )

  const { palette } = useTheme()

  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box>
            <Typography variant="h6">チケット購入履歴</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                mt: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{
                  mr: 1,
                }}
              >
                購入回数
              </Typography>
              <Typography variant="subtitle2">{tickets.length} 回</Typography>
            </Box>
          </Box>
          <Button variant="contained" startIcon={<AddIcon />}>
            チケット追加
          </Button>
        </Box>
        <Divider />
        <TableContainer
          sx={{
            maxHeight: isMobileDevice ? undefined : 200,
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  width="112"
                  sx={{ backgroundColor: palette.background.paper }}
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    購入額
                  </Typography>
                </TableCell>

                <TableCell sx={{ backgroundColor: palette.background.paper }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    納品日
                  </Typography>
                </TableCell>

                <TableCell sx={{ backgroundColor: palette.background.paper }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    入金日
                  </Typography>
                </TableCell>

                <TableCell
                  width="68"
                  sx={{ backgroundColor: palette.background.paper }}
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    編集
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{Array.map(generateRow)(tickets)}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

export default TicketPurchaseHistory
