import type { VFC } from 'react'
import { styled } from '@material-ui/core/styles'
import {
  AppBar,
  AppBarProps,
  Box,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import AccountPopover from './AccountPopover'
import MenuIcon from '../Icon/MenuIcon'

const NavigationBarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
}))

type NavigationBarProps = AppBarProps & {
  onSideBarMobileOpen?: () => void
}

const NavigationBar: VFC<NavigationBarProps> = ({
  onSideBarMobileOpen,
  ...other
}) => {
  return (
    <NavigationBarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSideBarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        <Box sx={{ ml: 2, mr: 0 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </NavigationBarRoot>
  )
}

export default NavigationBar
