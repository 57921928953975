import { VFC } from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { Navigate, useParams } from 'react-router-dom'

import { useCustomerConsumptionQuery } from '../graphql/gen/action'
import CustomHelmet from '../components/CustomHelmet'
import Loading from '../components/Loading'
import PageTransition from '../components/PageTransition'
import BreadCrumbs from '../components/BreadCrumbs'
import TicketPurchaseHistory from '../domain/TicketPurchaseHistory'
import PointBalanceSummary from '../domain/PointBalanceSummary'
import MonthlyPointStatement from '../domain/MonthlyPointStatement'
import RemoteRenderingSessionUsageChart from '../domain/RemoteRenderingSessionUsageChart'

const PointBalanceDetail: VFC = () => {
  const { customerId } = useParams()

  const { loading, data, error } = useCustomerConsumptionQuery({
    variables: {
      customerId,
    },
  })

  const helmet = (pathName?: string) => (
    <>
      <CustomHelmet title="ポイント残高" />
      <Box
        sx={{
          m: 3,
        }}
      >
        <BreadCrumbs pathName={pathName} />
      </Box>
    </>
  )

  if (loading) {
    return (
      <>
        {helmet()}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          <Loading />
        </Box>
      </>
    )
  }

  if (!data || !data.customer || error) {
    return (
      <>
        <Navigate to="/error" />
      </>
    )
  }

  const { id, customerName, currentContract } = data.customer
  const { totalCharge, balance, totalConsumptionAmount } =
    currentContract.remoteRenderingStates!

  const tickets =
    currentContract.pointTickets?.map((ticket) => ({
      id: ticket.id,
      charge: ticket.charge,
      deliveryDate: new Date(ticket.deliveryDate),
      depositedDate: ticket.depositedDate
        ? new Date(ticket.depositedDate)
        : null,
    })) ?? []

  const consumptions =
    currentContract.remoteRenderingStates?.consumptionPerMonth ?? []

  return (
    <>
      {helmet(customerName)}
      <PageTransition>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            m: 3,
          }}
        >
          <Typography variant="h3">{customerName}</Typography>
        </Box>
        <Container maxWidth={false}>
          <Grid container columns={2} spacing={2} alignItems="stretch">
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <PointBalanceSummary
                customerId={id}
                customerName={customerName}
                totalCharge={totalCharge}
                balance={balance}
                consumption={totalConsumptionAmount}
                contractStartDate={new Date(currentContract.startDate)}
                contractEndDate={new Date(currentContract.endDate)}
                type='detail'
              />
            </Grid>

            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <TicketPurchaseHistory tickets={tickets} />
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <RemoteRenderingSessionUsageChart consumptions={consumptions} />
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <MonthlyPointStatement consumptions={consumptions} />
            </Grid>
          </Grid>
        </Container>
      </PageTransition>
    </>
  )
}

export default PointBalanceDetail
