import type { VFC, ReactNode } from 'react'
import { List, ListSubheader } from '@material-ui/core'
import type { ListProps } from '@material-ui/core'
import { matchPath } from 'react-router-dom'
import NavItem from './NavItem'

type Item = {
  path?: string
  icon?: ReactNode
  info?: ReactNode
  children?: Item[]
  title: string
}

const renderNavItem = ({
  item,
  depth,
  pathname,
}: {
  item: Item
  depth: number
  pathname: string
}): JSX.Element => {
  const key = `${item.title}-${depth}`
  const active = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname,
      )
    : false

  return (
    <NavItem
      active={item.path === '/' ? item.path === pathname : active}
      depth={depth}
      icon={item.icon}
      info={item.info}
      key={key}
      path={item.path}
      title={item.title}
    />
  )
}

function renderNavItemHasChild({
  item,
  depth,
  pathname,
}: {
  item: Item
  depth: number
  pathname: string
}): JSX.Element {
  const key = `${item.title}-${depth}`
  const active = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname,
      )
    : false

  return (
    <NavItem
      active={active}
      depth={depth}
      icon={item.icon}
      info={item.info}
      key={key}
      open={active}
      path={item.path}
      title={item.title}
    >
      <List disablePadding>
        {item.children!.reduce<JSX.Element[]>((acc, child) => {
          if (child.children) {
            const nodes = renderNavItemHasChild({
              item: child,
              depth: depth + 1,
              pathname,
            })
            acc.push(nodes)
          } else {
            const node = renderNavItem({
              item: child,
              depth: depth + 1,
              pathname,
            })
            acc.push(node)
          }

          return acc
        }, [])}
      </List>
    </NavItem>
  )
}

const renderNavItems = ({
  items,
  pathname,
}: {
  items: Item[]
  pathname: string
  depth?: number
}) => (
  <List disablePadding>
    {items.reduce<JSX.Element[]>((acc, item) => {
      if (item.children) {
        acc.push(renderNavItemHasChild({ item, depth: 0, pathname }))
      } else {
        acc.push(renderNavItem({ item, depth: 0, pathname }))
      }

      return acc
    }, [])}
  </List>
)

type NavSectionProps = ListProps & {
  items: Item[]
  pathname: string
  title: string
}

const NavSection: VFC<NavSectionProps> = ({
  items,
  pathname,
  title,
  ...other
}) => (
  <List
    subheader={
      <ListSubheader
        disableGutters
        disableSticky
        sx={{
          color: 'text.primary',
          fontSize: '0.75rem',
          lineHeight: 2.5,
          fontWeight: 700,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </ListSubheader>
    }
    {...other}
  >
    {renderNavItems({
      items,
      pathname,
    })}
  </List>
)

export default NavSection
