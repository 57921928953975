import { EffectCallback, useEffect } from 'react'

/**
 * 副作用関数を1回だけ評価するhook
 * @param effect
 */
const useEffectOnce = (effect: EffectCallback) => {
  useEffect(effect, [])
}

export default useEffectOnce
