import type { VFC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Button,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import PageTransition from '../PageTransition'

const NotFoundError: VFC = () => {
  const isMobileDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )

  return (
    <>
      <PageTransition>
        <Helmet>
          <title>Not Found | mixpace monitor</title>
        </Helmet>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'inherit',
          }}
        >
          <Typography variant={isMobileDevice ? 'h4' : 'h1'}>
            404: ページが見つかりません
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
            sx={{ mt: 0.5, mb: 12 }}
          >
            URLの直接入力ではなく、画面左のナビゲーションバーを使って操作してください。
          </Typography>
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <Button variant="outlined">ホームへ戻る</Button>
          </RouterLink>
        </Box>
      </PageTransition>
    </>
  )
}

export default NotFoundError
