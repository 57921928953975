import type { VFC } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { PrintOutlined as PrintIcon } from '@material-ui/icons'
import StatementRow from './StatementRow'

type MonthlyConsumption = {
  term: string
  totalAmount: number
  standardSessionElapsedTime: number
  standardSessionAmount: number
  premiumSessionElapsedTime: number
  premiumSessionAmount: number
  remoteRenderingAssetConversionCount: number
  remoteRenderingAssetConversionAmount: number
}

type Props = {
  consumptions: MonthlyConsumption[]
}

const MonthlyPointStatement: VFC<Props> = ({ consumptions }) => {
  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Box>
            <Typography variant="h6">月次明細</Typography>
          </Box>
          <Button variant="contained" startIcon={<PrintIcon />}>
            CSV出力
          </Button>
        </Box>
        <Divider />
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="120" sx={{ py: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    利用月
                  </Typography>
                </TableCell>

                <TableCell width="160" align="right" sx={{ py: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    消費ポイント小計
                  </Typography>
                </TableCell>

                <TableCell align="right" sx={{ py: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Standard
                    <br />
                    利用時間 / 消費ポイント
                  </Typography>
                </TableCell>

                <TableCell align="right" sx={{ py: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Premium
                    <br />
                    利用時間 / 消費ポイント
                  </Typography>
                </TableCell>

                <TableCell align="right" sx={{ py: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    コンバート
                    <br />
                    回数 / 消費ポイント
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consumptions.map((consumption) => {
                return (
                  <StatementRow
                    key={consumption.term}
                    consumption={consumption}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

export default MonthlyPointStatement
